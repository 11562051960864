<!--门禁模块--警报页--表格组件-->
<template>
  <div id="alarmtable" ref="alarmtable">
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="size"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="chaozuo" slot-scope="text, record">
        <div>
          <a-popover placement="left">
            <template slot="content">
              <a-button type="primary">警报确认</a-button>
              <a-button type="primary">手动恢复</a-button>
              <a-button type="primary">应对</a-button>
              <a-button type="primary">顺位恢复</a-button>
              <a-button type="primary">警报输出</a-button>
              <a-button type="primary" @click="() => annotation(record)"
                >添加注释</a-button
              >
            </template>
            <a>选择操作</a>
          </a-popover>
        </div>
      </template>
    </a-table>
    <a-modal
      title="添加注释"
      centered
      :visible="visible"
      :closable="false"
      :ok-button-props="confirm_forbidden"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.alarmtable"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-textarea
        v-model="comment"
        placeholder="请输入内容......"
        :auto-size="{ minRows: 10, maxRows: 10 }"
        ref="text"
      />
    </a-modal>
  </div>
</template>

<script>
import { addComment } from "../../../api/door";

export default {
  name: "alarmtable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      size: { x: 2000, y: 240 },
      visible: false,
      comment: "",
      params: "",
      row: undefined,
      confirm_forbidden: { props: { disabled: true } },
      confirmLoading: false,
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 20, //每页中显示10条数据
        showTotal: (total) => `共 ${total} 条数据`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 65,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
        },
        {
          title: "发生日期",
          dataIndex: "dateTime",
          width: 170,
          ellipsis: true,
        },
        {
          title: "恢复日期",
          dataIndex: "normalTime",
          width: 170,
          ellipsis: true,
        },
        {
          title: "发报类型",
          dataIndex: "notificationMode",
          width: 120,
          ellipsis: true,
        },
        {
          title: "警报类型",
          dataIndex: "flcAlarmHistoryType",
          width: 140,
          ellipsis: true,
        },
        {
          title: "建筑物名称",
          dataIndex: "siteName",
          width: 140,
          ellipsis: true,
        },
        {
          title: "设备名称",
          dataIndex: "equipmentName1",
          width: 120,
          ellipsis: true,
        },
        {
          title: "视频",
          dataIndex: "equipmentName",
          width: 60,
          ellipsis: true,
        },
        {
          title: "信号/终端名称",
          dataIndex: "equipmentName2",
          width: 170,
          ellipsis: true,
        },
        {
          title: "警报内容",
          dataIndex: "eventMessage",
          width: 120,
          ellipsis: true,
        },
        {
          title: "应对状态",
          dataIndex: "correspondenceStatus",
          width: 100,
          ellipsis: true,
        },
        {
          title: "姓名",
          dataIndex: "personName",
          width: 100,
          ellipsis: true,
        },
        {
          title: "卡ID",
          dataIndex: "cardNo",
          width: 130,
          ellipsis: true,
        },
        {
          title: "体温",
          dataIndex: "bodyTemperature",
          width: 70,
          ellipsis: true,
        },
        {
          title: "注释",
          dataIndex: "description",
          width: 140,
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  watch: {
    // 监听添加注释框是否有值，无值时不允许确认修改
    comment: {
      handler(newVal, oldVal) {
        if (newVal !== "") {
          this.confirm_forbidden.props.disabled = false;
        } else {
          this.confirm_forbidden.props.disabled = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    tableSize() {
      let height = this.$refs.alarmtable.clientHeight;
      let tableHeight = height - 170;
      this.size.y = tableHeight;
      console.log(tableHeight)
    },
    // 打开添加注释的对话框并且获取对应ID值
    annotation(key) {
      this.visible = true;
      this.params = key.param;
      this.row = key;
      setTimeout(() => {
        this.$refs.text.focus();
      }, 5);
      // console.log(key);
    },
    // 添加注释对话框的确定按钮
    handleOk() {
      this.confirmLoading = true;
      let data = {
        params: this.params,
        comment: this.comment,
      };
      addComment(data)
        .then((res) => {
          if (res.errorCode == "00") {
            this.confirmLoading = false;
            this.row.description = this.comment;
            this.visible = false;
            this.comment = "";
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.confirmLoading = false;
            this.$message.error(res.msg);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加注释对话框的取消按钮
    handleCancel(e) {
      this.visible = false;
      this.comment = "";
    },
  },
};
</script>

<style scoped>
#alarmtable{
  height: 100%;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
button {
  margin-right: 10px;
}
</style>