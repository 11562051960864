<!--门禁模块--警报页-->
<template>
  <div id="alarm">
    <div class="header">
      <my-headertitle>警报</my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item label="设备名称">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item label="类型选择">
              <a-select
                v-model="form.floor"
                style="width: 120px"
                placeholder="please select your zone"
              >
                <a-select-option
                  v-for="(tiem, value) in options"
                  :key="value"
                  >{{ tiem.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item label="初始日期/时间选择" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @openChange="handleStartOpenChange"
                @change="handleCalendarChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item label="终止日期/时间选择" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">查询</a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <div class="main">
      <my-tabletitle>警报列表</my-tabletitle>
      <my-alarmtable :data="data" :loading="loading"></my-alarmtable>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import alarmTable from "./components/alarmTable";
import { alarmData, getTenantList } from "../../api/door";
import { getdata } from "../../api/api";
import moment from "moment";

export default {
  name: "alarm",
  data() {
    return {
      tabletitle: "警报列表",
      endOpen: false,
      form: {
        name: "",
        floor: 0,
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      options: [],
      data: undefined,
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          { required: true, message: "请选择日期或时间", trigger: "change" },
        ],
        date2: [
          { required: true, message: " 请选择日期或时间", trigger: "change" },
        ],
      },
    };
  },
  // 获取警报页楼层/租户选择列表
  created() {
    getTenantList(this.$route.query.id)
      .then((res) => {
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.options.push({ value: data[i].id, name: data[i].name });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    // 判断需要查询的条件
    conditionNo: function () {
      if (this.form.name == "") {
        return 0;
      } else {
        return 2;
      }
    },
  },
  methods: {
    moment,
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      this.startDate = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
      this.form.date2 = null;
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            tenantId: this.options[this.form.floor].value,
            conditionNo: this.conditionNo,
            condition: this.form.name,
            startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
          };
          alarmData(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.form.date2;
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
    // 初始日期/时间选择弹出日历和关闭日历的回调
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    // 终止日期/时间选择弹出日历和关闭日历的回调
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-alarmtable": alarmTable,
  },
};
</script>

<style scoped>
#alarm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 140px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 140px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
}
.fool {
  width: 100px;
  height: 10px;
}
button {
  width: 120px;
}
</style>